.slideshow-container {
    --image-width: 150px; 
    --num-images: 8;
    --animation-speed: 20s;
    width: 100vw;
    height: auto;
    padding: 2em 0;
    position: relative;
    display: flex;
    flex-direction: row;
    overflow: hidden;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.slideshow-container .slide-track {
    animation: scroll var(--animation-speed) linear infinite;
    display: flex;
    width: calc(var(--image-width) * var(--num-images) * 4);
}

@keyframes scroll {
    0% {
        transform: translateX(calc(-1 * var(--image-width) * var(--num-images)));
    }
    100% {
        transform: translateX(0);
    }
}