.notfound-container {
    width: 100vw;
    height: 80vh;
    display: flex;
    flex-direction: column;
    background-color: #000;
    justify-content: center;
    align-items: center;
    color: white;
}

.notfound-container h1 {
    font-size: calc(2em + 8vw);
    margin: 0;
}

.notfound-container h3 {
    font-size: calc(0.5em + 3vw);
    margin: 0;
}

.notfound-container span {
    font-family: 'CorvinusSkyline';
    font-size: calc(1em + 4vw);
}