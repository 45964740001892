.App {
  text-align: center;
  background-color: black;
  font-family: Poppins, sans-serif;
}

.logo {
  cursor: default;
  font-size: 3rem;
  font-family: 'CorvinusSkyline';
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.logo-color1 {
  color: rgb(240,190,0);
}

.logo-color2 {
  color: rgb(248,0,0);
}

.description-container {
  background-color: #181818;
  margin: 50px auto;
  padding: 2em;
  width: 80%;
  max-width: 865px;
  color: #dddddd;
  font-weight: 400;
}

/* Fonts */

@font-face {
  font-family: "CorvinusSkyline";   /*Can be any text*/
  src: local("CorvinusSkylineICG"),
    url("./assets/fonts/CorvinusSkylineICG.ttf") format("truetype");
}

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');