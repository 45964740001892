.contactform-container {
    max-width: 600px;
    margin: auto;
    color: white
}

form {
    display: grid;
    gap: 16px;
}

.form-row {
    display: flex;
    flex-direction: column;
}

.form-row.center {
    flex-direction: row;
    justify-content: space-between;
}

.form-row.center .column {
    width: 48%;
}

label {
    margin-bottom: 4px;
}

input, textarea {
    color: white;
    padding: 8px;
    border: 0 1px 0 solid #ccc;
    background-color: #444444;
    resize: vertical;
}

.column {
    display: flex;
    flex-direction: column;
}

button {
    font-size: 1.2em;
    font-weight: 700;
    padding: 10px;
    background-color: #007bff;
    
    color: white;
    border: none;
    cursor: pointer;
}

button:hover {
    background-color: #0056b3;
}

button:disabled {
    background-color: rgba(0, 0, 0, 0.1); /* Adjust the alpha value (last parameter) for transparency */
    color: white;
    border: 2px solid white;
    cursor: not-allowed;
}

.error-message {
    color: rgb(255, 54, 54);
    margin-top: 5px;
    font-size: 0.8em;
    font-weight: 400;
    height: 1em;
}

label .required {
    color: rgb(255, 54, 54);
}