.show-entry {
    color: white;
}

.show-entry:nth-child(even) {
    background-color: #222222;
}

.show-entry .date {
    font-weight: 800;
    color: rgb(55, 168, 243);
}

.show-entry .venue {
    font-weight: 400;
    color: #dddddd;
}

.show-entry .location {
    
}

.show-entry .extended-location {
    display: flex;
    flex-direction: column;
    justify-content: center;
}


.show-entry .info-button {
    padding: 8px 16px;
    background-color: transparent;
    color: white;
    border: 2px solid white;
    border-radius: 0.25em;
    cursor: pointer;
    transition: background-color 0.3s; 
    min-width: 120px;
    font-size: 0.8em;
}

.show-entry .info-button:hover {
    background-color: rgb(248,0,0);
}

.show-entry .info-button:disabled {
    color: black;
    background-color: #333333;
    border-color: #222222;
    cursor: not-allowed;
  }

@media (min-width: 850px) {
    .show-entry .date {
        min-width: 200px;
    }
}
