.press-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 80vh;
    width: 100vw;
    padding: 80px 0 20px;
}

.press-container h2 {
    font-size: calc(1.2rem + 3vw);
    margin: 20px;
}

.press-review-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80%;
}

.review-container {
    background-color: #222222;
    box-shadow: 2px 2px white;
    margin: 1em 0;
    border-radius: 0.25rem;
    width: 100%;
    max-width: 600px;
    text-align: left;
}

.review-container-content {
    padding: 1.5em 1.5em;
}

.review-container .review-container-content .author {
    font-size: 1em;
    font-weight: 700;
    color: white;
    padding-bottom: 0.5em;
}

.review-container .review-container-content .review {
    font-size: 1em;
    font-weight: 400;
    color: #dddddd;
}

.odd {
    align-self: flex-start;
}

.even {
    align-self: flex-end;
}

.press-container .contact-info {
    color: white;
    width: 80vw;
    background-color: #181818;
    padding: 20px 40px 20px;
    border-radius: .25rem;
    font-weight: 400;
}

.press-container .contact-info span {
    color: #3bacf3;
}

.press-container .contact-info a {
    color: #3bacf3;
}