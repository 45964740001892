.sidebar {
    position: fixed;
    top: 0;
    bottom: 0;
    left: -100%;
    background-color: black;
    padding: 1rem 2rem;
    width: 65vw;
    max-width: 300px;
    transition: left 0.5s ease;
    z-index: 1001;
}

.sidebar.active {
    left: 0;
}

.sidebar .menu {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin: 2rem 0rem;
}

.sidebar .menu a {
    padding: 0.8rem;
    font-size: 2em;
}