.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-content {
    background: #eeeeee;
    padding: 20px;
    text-align: center;
    width: 50vw;
}

.modal-content p {
    color: #181818;
}

.modal-content .title {
    font-size: calc(1em + 2vw);
    font-weight: 800;
}

.modal-content .caption {
    font-size: calc(0.8em + 1vw);
}

.close-btn {
    position: relative;
    cursor: pointer;
    font-size: calc(3em + 2vw);
    font-family: 'Times New Roman', Times, serif;
    color: #888;
}
  
.close-btn:hover {
    color: rgb(248, 0, 0);
}
