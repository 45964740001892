/* src/components/Navbar.css */
.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2rem;
    background-color: rgba(0,0,0,0);
    color: white;
    transition: background-color 0.5s ease;
    position: fixed;
    left: 0;
	right: 0;
    z-index: 1000;
}

.navbar a {
    text-decoration: none;
    cursor: pointer;
}

.bg-active {
    background-color: rgba(0,0,0,0.6);
}

.desktop-menu .menu {
    display: flex;
    gap: 2rem;
}
  
.menu a {
    text-decoration: none;
    color: white;
    font-size: 1.6rem;
    transition: 0.3s;
}

.menu a:hover {
    color: rgb(240,190,0)
}
  
.hamburger-menu {
    display: none;
    cursor: pointer;
    font-size: 1.5rem;
}
  
/* Media query for small screens */
@media (max-width: 930px) {
    .desktop-menu {
        display: none;
    }

    .hamburger-menu {
        display: block;
    }
}