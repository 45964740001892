.shows-container {
    padding: 80px 0 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.shows-container h2 {
    font-size: calc(1.2rem + 3vw);
    margin: 20px;
}

.shows-container .emphasis {
    font-weight: 800;
    color: white;
}

.shows-container table.schedule {
    border-collapse: separate;
    border-spacing: 0;
    margin: 0 auto;
    padding: 0 10vw;
    width: 80vw;
    min-width: 400px;
    max-width: 1600px;
}

.shows-container table.schedule td {
    border-top: 2px solid #FFF;   
    padding: 0.5em 1em;
}

.shows-container .past-shows-container {
    margin: 80px 0;
}