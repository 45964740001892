.footer-container {
    height: 15vh;
    max-height: 200px;
    width: 100vw;
    justify-content: center;
    align-items: center;
    background-color: rgb(0, 0, 0);
    color: white;
}

.logo-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.logo-container a img {
    width: 40px;
    padding: 1rem;
}

.footer-container .copyright {

}