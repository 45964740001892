.booking-container {
    padding: 80px 0 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.booking-container-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
}

.booking-container h2 {
    font-size: calc(1.2rem + 3vw);
    margin: 20px;
}

.booking-container-division {
    margin: 2em;
    width: min(90vw, 500px);
    min-width: 300px;
    background-color: #222222;;
    box-shadow: 2px 2px white;
    text-align: left;
    padding: 40px 40px;
}

.booking-container-division h2 {
    font-size: 2em;
    color: #dddddd;
    padding: 20px 0;
    margin: 0;
}

.booking-container-division p {
    font-size: 1.2em;
    font-weight: 400;
    color: #dddddd;
    padding: 0;
    margin: 0;
}

.booking-container-division p .emphasis {
    color: #ffffff;
    font-weight: 800;
}

.booking-container-division p a {
    text-decoration: none;
    color: rgb(55, 168, 243);
}

.booking-button-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    margin: 20px 0;
}

.booking-button img {
    padding: 10px;
    width: 70px;
}