.home-container {
    background-color: rgb(0, 0, 0);
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-top: 80px;
}

.home-container .row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 100px;
    font-size: calc(10px + 2vmin);
    color: white;
    z-index: 1;
}

.home-container img {
    height: 300px;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.home-container h1 {
    font-weight: 800;
    font-size: calc(1.2rem + 3vw);
    display: inline-block;
    text-align: left;
    line-height: 1.7em;
}

.home-container h1 .title-emphasis {
    font-size: 2.4em;
    font-family: 'CorvinusSkyline';
}

.home-container .description {
    font-weight: 400;
    font-size: calc(8px + 1.5vmin);;
    max-width: 1000px;
}

.home-container .description p {
    padding: 20px 40px 20px;
    background-color: #181818;
    color: rgb(215, 215, 215);
    border-radius: .25rem;
    box-shadow: 2px 2px white;
}

.home-container .description p .emphasis {
    color: rgb(255, 255, 255);
    font-weight: 700;
}

.sticker {
}

#worldsgreatesttributebands {
    height: 100px;
}

.home-container .spotlight-container {
    position: absolute;
    height: 100vh;
    width: 100vw;
    z-index: 0;
    top: 0;
    left: 0;
    overflow: hidden;
}

.home-container .spotlight {
    position: absolute;
    height: 600px;
    width: 600px;
    opacity: 0.3;
}

.home-container .spotlight.blue {
    background: radial-gradient(closest-side, #04b1fb, rgba(0,0,0,0), rgba(0,0,0,0));
}

.home-container .spotlight.pink {
    background: radial-gradient(closest-side, #ff00fb, rgba(0,0,0,0), rgba(0,0,0,0));
}

.home-container .spotlight.yellow {
    background: radial-gradient(closest-side, #fffb00, rgba(0,0,0,0), rgba(0,0,0,0));
}

#spotlight1 {
    top: 5vh;
    left: 25vw;
    transform-origin: 55% 55%;
    animation: spin_cw 10s infinite linear;
}

#spotlight2 {
    top: 20vh;
    left: 30vw;
    transform-origin: 60% 60%;
    animation: spin_cw 10s infinite linear;
}

#spotlight3 {
    top: -20vh;
    left: -10vw;
    transform-origin: 53% 53%;
    animation: spin_ccw 5s infinite linear;
}

#spotlight4 {
    top: 0vh;
    left: 70vw;
    transform-origin: 51.5% 51.5%;
    animation: spin_ccw 20s infinite linear;
}


@keyframes spin_cw {
    from {transform:rotate(0deg);}
    to   {transform:rotate(360deg);}
}

@keyframes spin_ccw {
    from {transform:rotate(0deg);}
    to   {transform:rotate(360deg);}
}

/* Media query for small screens */
@media (max-width: 768px) {
    .home-container .row {
        flex-direction: column;
        gap: 20px;
    }
}