.videocard {
    position: relative;
    display: flex;
    flex-direction: column;
    margin: 20px;
    height: 420px;
    width: 300px;
}
  
.videocard iframe {
    width: 300px;
    height: 180px;
    border: none;
    z-index: 1;
}

.videocard .videocard-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 0.5rem;
    background-color: #eeeeee;
}

.videocard .videocard-description-container {
    padding: 1em;
    text-align: left;
    color: black;
    z-index: 2;
}

.videocard .videocard-description-container .title {
    font-weight: 700;
    font-size: 1rem;
    padding-bottom: 0.5em;
}

.videocard .videocard-description-container .description {
    font-weight: 400;
    font-size: 1rem;
}