.media-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;
    padding: 80px 0 20px;
}

.media-container h2 {
    font-size: calc(1.2rem + 3vw);
    margin: 20px;
}

.media-container .description-container {
    margin-top: 0;
}

.section-container-outer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
}

.section-container-inner {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
}

.section-container {
    display: flex;
    flex-direction: row;

    justify-content: center;
    flex-wrap: wrap;
}