.photocard-container {
    position: relative;
    display: flex;
    flex-direction: column;
    margin: 20px;
    padding: 0;
}

img.photocard {
    display: block;
    max-height: 350px;
    max-width: 90vw;
    z-index: 2;
}

.photocard-container .infocard {
    height: 35px;
    border-radius: 3px;
    background-color: #eeeeee;
    z-index: 0;
    transform: translateY(-10px);
}

.photocard-container .copyright {
    color: black;
    z-index: 1;
    margin: 0.3rem 0.5rem;
    font-weight: 400;
    font-size: 0.1em;
    position: absolute; 
    bottom: 0; 
}